<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>
          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="3" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Название: </a-col>
            <a-col :span="15" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                name="linkName"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>

          <!-- status toggle -->
          <a-row style="margin-top: 25px">
            <a-col :span="3" class="form_title">Статус:</a-col>
            <a-switch v-model="form.is_active" />
          </a-row>

          <a-row style="margin-top: 20px">
            <a-col :span="3" />
            <a-col :span="12">
              <a-row>
                <a-button style="margin-right: 10px" @click="$router.go(-1)">
                  <a-icon type="close" />Отмена
                </a-button>
                <a-button
                  style="margin-left: 10px"
                  type="primary"
                  @click="
                    submit(form.id ? (saveback = false) : (saveback = true))
                  "
                >
                  <a-icon type="check" />
                  {{ form.id ? $t("Save") : $t("Add") }}
                </a-button>
                <a-button
                  v-if="form.id"
                  style="margin-left: 10px"
                  type="primary"
                  @click="submit((saveback = true))"
                >
                  <a-icon type="check" />
                  {{ $t("SaveAndExit") }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
export default {
  components: {
    // "image-upload": () => import("@/components/utils/image-upload")
  },
  data() {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        is_active: false
      },
      langTab: "oz"
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      if (!this.$route.params.id) return
      let id = this.$route.params.id
      const { data } = await this.$api.get(
        `/admin/vacancy/departament/${id}/detail/`
      )
      this.form = data
    },
    async submit(saveback) {
      let f = new FormData()
      this.form = {
        ...this.form
      }
      Object.keys(this.form).forEach((key) => {
        f.append(key, this.form[key])
      })
      const createOrUpdate = {
        url:
          this.form && this.form.id
            ? "/admin/vacancy/departament/" + this.form.id + "/update/"
            : "/admin/vacancy/departament/create/",
        method: this.form && this.form.id ? "patch" : "post"
      }
      try {
        let res = await this.$api[createOrUpdate.method](createOrUpdate.url, f)
        if ((res && res.status === 201) || res.status === 200) {
          this.$message.success(
            `Успешно ${this.form.id ? "обновлена" : "создана"}!`
          )
        }
        if (saveback) {
          this.$router.push({ name: "vacDepart" })
        }
      } catch (e) {
        this.$message.error(
          `Ошибка при ${this.form.id ? "обновлении" : "создании"}!`
        )
        console.error(e)
      }
    }
  }
}
</script>

<style>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
.isInvalidUrl {
  border: 1px solid red;
  box-shadow: 0 0 3px red;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>
